import React from "react";
import HeroBackground from "@insightfulscience/atomic-react/Hero/HeroBackground";
import Hero from "@insightfulscience/atomic-react/Hero";
import { HeroCol, HeroRow } from "@insightfulscience/atomic-react/Hero/Grid";
import HeroDescription from "@insightfulscience/atomic-react/Hero/Description";
import HeroTitle from "@insightfulscience/atomic-react/Hero/Title";
import { useLocale } from "@insightfulscience/smart-react/i18";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";
import Feature from "@insightfulscience/atomic-react/Feature";
import Block from "@insightfulscience/atomic-react/Block";
import FeatureMeta from "@insightfulscience/atomic-react/Feature/Meta";
import FeatureImage from "@insightfulscience/atomic-react/Feature/Image";
import WistiaVideoEmbed from "@insightfulscience/atomic-react/WistiaVideo/Embed";
import WistiaVideo from "@insightfulscience/atomic-react/WistiaVideo";
import Section from "@insightfulscience/atomic-react/Section";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import LogoCircle from "@insightfulscience/atomic-react/LogoCircle";
import LandingSimpleWithBackground from "@insightfulscience/atomic-react/LandingElement/SimpleSectionWithBackground";
import Box from "@insightfulscience/atomic-react/Box";
import Image from "@insightfulscience/atomic-react/Image";
import Link from "@insightfulscience/atomic-react/Link";
import Icon from "@insightfulscience/atomic-react/Icon";
import PageMeta from "@insightfulscience/smart-react/PageMeta";
import SiteLayout from "../../SiteLayout";
import SectionTryForFree from "../../SectionTryForFree";
import routes from "../../routes";

const About = () => {
	const [t] = useLocale("about");
	return (
		<SiteLayout>
			<PageMeta title={t("META.TITLE")} description={t("META.DESCRIPTION")}>
				<PageMeta.OGP title={t("META.TITLE")} description={t("META.DESCRIPTION")} />
				<PageMeta.Canonical href={t("META.CANONICAL")} />
			</PageMeta>
			<HeroBackground.Images
				color="4"
				images={[t("HERO_IMAGE_1"), t("HERO_IMAGE_2")]}
				imagePositions={["left center", "100% center"]}
			>
				<Hero sx={{ textAlign: "left" }} color="transparent" spacing={{ py: 18 }}>
					<HeroRow mx center={false} utility={{ align: { items: "center" } }}>
						<HeroCol md={12} lg={7} smOrder={1} mdOrder={0}>
							<HeroDescription
								sx={{
									fontWeight: "medium",
									fontSize: "l",
									fontColor: "black",
									textAlign: "left",
								}}
							>
								{t("ABOUT")}
							</HeroDescription>
							<HeroTitle
								spacing={{ sm: { mt: 6 }, md: { mt: 0 } }}
								sx={{ fontColor: "black", textAlign: "left" }}
							>
								{t("HERO_TITLE")}
							</HeroTitle>
							<HeroDescription sx={{ fontColor: "black", textAlign: "left" }}>
								{t("HERO_DESCRIPTION")}
							</HeroDescription>
						</HeroCol>
					</HeroRow>
				</Hero>
			</HeroBackground.Images>
			<LandingSimpleSection spacing={{ py: 18 }}>
				<Feature px={false}>
					<FeatureMeta indent={false}>
						<Block spacing={{ pr: 10 }}>
							<FeatureMeta.Title Tag="h3">{t("INTEGRATED_SOLUTIONS_TITLE")}</FeatureMeta.Title>
							<FeatureMeta.Description sx={{ fontColor: "black--custom-1" }}>
								{t("INTEGRATED_SOLUTIONS_DESC_1")}
							</FeatureMeta.Description>
							<FeatureMeta.Description sx={{ fontColor: "black--custom-1" }}>
								{t("INTEGRATED_SOLUTIONS_DESC_2")}
							</FeatureMeta.Description>
							<FeatureMeta.Description sx={{ fontColor: "black--custom-1" }}>
								{t("INTEGRATED_SOLUTIONS_DESC_3")}
							</FeatureMeta.Description>
							<FeatureMeta.Description sx={{ fontColor: "black--custom-1" }}>
								{t("INTEGRATED_SOLUTIONS_DESC_4")}
							</FeatureMeta.Description>
						</Block>
					</FeatureMeta>
					<FeatureImage imageClassName="max-h-128--lg" src={t("INTEGRATED_SOLUTIONS_IMAGE")} />
				</Feature>
				<Section spacing={{ mt: 16 }}>
					<WistiaVideo>
						<WistiaVideoEmbed id="kgqjpac2mx" />
					</WistiaVideo>
				</Section>
			</LandingSimpleSection>
			<LandingSimpleSection isBorderNone>
				<LandingSimpleSection.Title
					spacing={{ mb: 15 }}
					sx={{ fontSize: "l", fontWeight: "medium" }}
				>
					{t("ORGANIZATION_TITLE")}
				</LandingSimpleSection.Title>
				<LandingSimpleSection.Body>
					<Row mx my>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_1")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_2")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_3")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_4")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_5")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_6")} />
						</Col>
					</Row>
					<Row mx my center>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_7")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_8")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_9")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_10")} />
						</Col>
						<Col md={2} sm={4} sx={{ textAlign: "center" }}>
							<LogoCircle wrapperUtility={{ bgColor: "4" }} imgSrc={t("ORGANIZATION_LOGO_11")} />
						</Col>
					</Row>
				</LandingSimpleSection.Body>
			</LandingSimpleSection>
			<LandingSimpleWithBackground bgColor={4} className="py-30">
				<Row utility={{ justify: "center" }}>
					<Col lg={12}>
						<Box bgColor="transparent" spacing={{ py: 0 }}>
							<Feature px={false}>
								<FeatureMeta sm={12} md={4} indent={false}>
									<Image src={t("DOTMATICS_LOGO")} spacing={{ mb: 10 }} />
									<FeatureMeta.Description>{t("DOTMATICS_DESC_1")}</FeatureMeta.Description>
									<FeatureMeta.Description>{t("DOTMATICS_DESC_2")}</FeatureMeta.Description>
									<FeatureMeta.Description spacing={{ mt: 10 }}>
										<Link href={routes.external.platform} sx={{ fontWeight: "medium" }}>
											{t("DOTMATICS_LINK")} <Icon name="angle-right" color="3" />
										</Link>
									</FeatureMeta.Description>
								</FeatureMeta>
								<FeatureImage
									sm={0}
									md={8}
									src={t("DOTMATICS_SCHEME")}
									sx={{ textAlign: "center" }}
									utility={{ sm: { display: "none" } }}
								/>
							</Feature>
						</Box>
					</Col>
				</Row>
			</LandingSimpleWithBackground>
			<SectionTryForFree />
		</SiteLayout>
	);
};

export default About;
